<template>
  <div class="wrap">
    <header class="header">
      <img src="/images/besttowns/title.png" class="title-img">

    </header>
    <div class="d-f-c">
      <div class="content">
        <div class="content-header">
          <h3 class="content-total-text">精选小镇</h3>

        </div>
        <ul class="content-list">
          <li
              v-for="item in list"
              :key="item"
              class="list-item"
              @click="goPage(`townsdetail?tid=${item.id}&tn=${item.name}`)"
          >
            <TownItem :data="item"/>
          </li>
        </ul>
        <NoData :visible="!list.length" />
        <div class="page">
          <Page
              class="page-tools"
              :size="params.pageSize"
              :current="pageNum"
              :total="total"
              @pageChange="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Page from '@/components/common/pagination'
import NoData from '@/components/common/nodata/index.vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'


import TownItem from '@/components/common/townitem'
export default {
  name: 'betsttowns',
  components: {
    Page,
    NoData,


    TownItem
  },
  setup () {
    const route = useRoute()
    const { params, search, } = useParams({
      pageNum: route.query?.pn ?? 1,
      pageSize: 8
    })

    //getTownCover
    const { pageNum, total, list, getList } = useList(params, api.bestTowns)
    onBeforeRouteUpdate((to) => {

      search({
        pageNum: to.query?.pn,
        name: to.query?.name
      })
    })



    return {
      pageNum,
      total,
      params,
      list,
      getList,
      search,


    }

  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    resetParams () {
      this.$router.push({
        query: {
          pn: 1
        }
      })
    },
    pageChange (page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          pn: page
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);


  .header {
    height: 7.36rem;
    width: 100%;
    background: url("/images/besttowns/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title-img {
      width: 6.4rem;
      height: 1.6rem;
      position: absolute;
    }

  }
  .content {
    width: 12rem;
    min-height: calc(100vh - 5.6rem);
    padding: 0.6rem 0;
    position: relative;


    .content-header {
      display: flex;
      justify-content: space-between;
    }
    .content-total-text {
      font-size: 0.3rem;
      font-weight: 500;
      color: #000000;
    }


    .content-tag {
      top: 0.15rem;
      position: absolute;
      background: #A37F4B;
      font-size: 0.14rem;
      padding: 0.08rem 0.1rem;
      border-radius: 0.04rem;
      color: #fff;
      cursor: pointer;
    }
    .content-title {
      font-size: 0.18rem;
      font-weight: bold;
      color: #333333;
    }
    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-top: 0.4rem;

      .list-item {
        position: relative;

        margin-bottom: 0.8rem;
        transition: all 300ms;
        &:not(:nth-of-type(4n)) {
          margin-right: 0.16rem;
        }
        &:hover {
          transition: all 300ms;
          transform: scale(1.05);
          cursor: pointer;
        }
        .item-border {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5.12rem;
        }
        .item-cover {
          display: block;
          width: 100%;
          height: 5.12rem;
          object-fit: cover;
        }
        .item-title {
          margin-top: 0.14rem;
          font-size: 0.16rem;
          font-weight: normal;
          color: #333333;
          @extend .text-oneLine;
        }
      }
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
}
</style>
